import React from 'react';
import { Button } from 'semantic-ui-react';

import './Launch.css';
import clientConfig from './client.json';
const client: ClientJson = clientConfig as ClientJson;

class ClientJson {
  description?: string = 'description';
  title?: string = 'title';
}

interface LaunchProps {
  Launch: () => void;
}

export const LaunchView: React.FC<LaunchProps> = (props: LaunchProps) => {
  return (
    <div id="launchContainer">
      <div>
		
		<img alt="Catalyst Logo" src="/Catalyst_Home_Logo.png" style={{ width: 150}}/>
		<br/ >
		<h1 className="bigTitle">CATALYST</h1>
		<h4 className="subTitle">a vision for lakeland</h4>	  
        <h1>{client.description}</h1>
        <Button onClick={() => props.Launch()}>
			Enter Catalyst Lakeland
		</Button>
      </div>
    </div>
  );
};
