import React, { useState, useEffect } from 'react';
  
const Progress_bar = ({bgcolor,duration,height}) => {
  const [progress, setProgress] = useState(0);

  const updateProgress = () => {
    const increment = 100 / (duration * 60); // Duration is the number of minutes
    // setProgress(prevProgress => prevProgress + increment);
    setProgress(progress => progress + increment);
  };

  useEffect(() => {
    const intervalId = setInterval(updateProgress, 1000);
    return () => clearInterval(intervalId);
  }, []);
  
  const Parentdiv = {
    height: height,
    width: 'auto',
    backgroundColor: 'whitesmoke',
    borderRadius: 40,
    margin: 50
  }
  
  const Childdiv = {
    height: '100%',
    width: `${progress}%`,
    maxWidth: '100%',
    backgroundColor: bgcolor,
    borderRadius:40,
    textAlign: 'right'
  }
  
  const progresstext = {
    padding: 10,
    color: 'black',
    fontWeight: 900
  }
      

  return (
  <div style={Parentdiv}>
    <div style={Childdiv}>
      <span style={progresstext}></span>
    </div>
  </div>
  )
}

// const interval = setInterval (() => { progress = progress + 50 }, 100000);
  
export default Progress_bar;